<template>
  <validation-provider
    #default="validationContext"
    name="Entity"
    :rules="rules"
  >
    <b-form-group
      :label="label"
      label-for="islamic-org"
    >
      <v-select
        id="islamic-org"
        v-model="inpoIdTemp"
        :options="entities"
        :reduce="inpo => inpo.id"
        placeholder="Select Entity"
        label="name"
        :selectable="option => ! option.disabled"
        @input="setInpoID"
      />
      <b-form-invalid-feedback :state="getValidationState(validationContext)">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
import vSelect from 'vue-select'
import formValidation from '@/@core/comp-functions/forms/form-validation'

export default {
  name: 'InpoSelect',
  components: {
    vSelect,
  },
  props: {
    rules: { type: String, default: '' },
    inpoId: { type: [Number, String], default: null },
    label: { type: String, default: '' },
    isAllEntitiesDisabled: { type: Boolean, default: false },
  },
  data() {
    return {
      inpoIdTemp: this.inpoId,
      entities: [{ name: 'All Entities', id: null, disabled: this.isAllEntitiesDisabled }],
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    return { getValidationState }
  },
  created() {
    this.getEntities()
  },
  methods: {
    getEntities() {
      this.$entities.get('/internalops/entity-list-autocomplete').then(res => {
        this.entities.push(...res.data.data)
      })
    },
    setInpoID(id) {
      this.$emit('update:inpoId', id)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
