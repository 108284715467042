<template>
  <b-card>
    <b-table
      ref="table"
      :items="getDonationCategories"
      :fields="tableColumns"
      :current-page="pagination.currentPage"
      :per-page="pagination.perPage"
      responsive
      empty-text="No Donation Categories found"
      show-empty
      @sort-changed="onSortChanged"
    >
      <template #cell(id)="{item}">
        <span> # {{ item.id }} </span>
      </template>
      <template #cell(name)="{item}">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="70"
              :src="$entitiesImagesURL + '/' + item.icon"
              :text="avatarText(item.category_name.slice(0,10))"
              rounded
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ item.category_name }}
          </span>
        </b-media>
      </template>
      <template #cell(status)="{item}">
        <b-badge
          pill
          :variant="`light-${resolveUserStatusVariant(item.status)}`"
          class="text-capitalize"
        >
          <span> {{ resolveUserStatusValue(item.status) }}</span>
        </b-badge>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="{item}">
        <b-dropdown
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <toggle-status
            :item="item"
            :activate-url="activateUrl"
            :deactivate-url="deactivateUrl"
          />
          <b-dropdown-item
            v-if="$can('update','global-DonationCategoryController')"
            v-b-modal.edit-modal
            variant="outline-primary"
            @click="getCategoryById(item.id)"
          >
            <feather-icon icon="EditIcon" />
            <span> Edit </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import { avatarText } from '@core/utils/filter'
import ToggleStatus from '@/common/components/GeneralSettings/common/ToggleStatus.vue'

export default {
  name: 'DonationCategoriesTable',
  components: { ToggleStatus },
  props: {
    getDonationCategories: { type: Function, default: () => [] },
    pagination: { type: Object, default: () => {} },
  },
  data() {
    return {
      tableColumns: [
        { key: 'id', label: '#', sortable: true },
        { key: 'name', sortable: true },
        { key: 'use_type', label: 'donation use', sortable: true },
        { key: 'status', sortable: true },
        { key: 'entities_count', sortable: true },
        { key: 'actions' },
      ],
      deleteEndPoint: `${process.env.VUE_APP_ENTITIES_BASE_URL}/internalops/donation-categories/`,
      activateUrl: '/internalops/donation-category/activate/',
      deactivateUrl: '/internalops/donation-category/deactivate/',
    }
  },
  methods: {
    avatarText,
    onSortChanged(ctx) {
      this.$emit('onSort', ctx)
    },
    getCategoryById(id) {
      this.$entities.get(`internalops/donation-categories/${id}`).then(res => {
        this.$emit('update:category', res.data.data)
      })
    },
    resolveUserStatusValue(status) {
      return status ? 'Active' : 'Not Active'
    },
    resolveUserStatusVariant(status) {
      return status ? 'success' : 'warning'
    },
  },
}
</script>
<style lang="">

</style>
